header.banner {
	background:url('../images/upg-header.svg');
	background-repeat:no-repeat;
	height:50px;
	background-position:left bottom;
	background-size:cover;
	.container-fluid {
		height:100%;
		.row {
			height:100%;
		}
	}
	.logo-link {
		a {
			display: block;
			width:100%;
			height:100%;
		}
	}
	.nav-primary {
		height:100%;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		p {

			@include use-clan-bold();
			
			font-size:14px;
			color:white;
			
			a {	
				text-transform: uppercase;
				border-left:1px solid $gold;
				margin-left:12px;
				padding-left:15px;
				color:white;
			}
		}
	}
}