.error404 {
	.page-content {
		text-align: center;
	}
	.intro-content {
		padding-bottom:0;
	}
	.form-header {
		margin-bottom:0;

	}
	main {
        > .container {
            height:100%;
            .justify-content-md-center {
                height:100%;
            }
        }
    }
    .row-content-container {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
    }

}