html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba($black, 0);
  height:100%;
 }

body {
    @include use-clan();
    margin: 0;
    font-weight: 400;
    color: $main-text;
    text-align: left;
    background-color: #fff;
    font-size:0.9em;
    min-height:100%;
    background-repeat:no-repeat;    

 }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

.theme-wrap {
    width: 100%;
    
    overflow-x: hidden !important;
    opacity: 0;
    transition: all 220ms ease;
    background-repeat:no-repeat;
    background-size:cover;
    &.show-theme {
        opacity: 1;
    }
    > .container {
        flex:1;
        padding-bottom:45px;
    }
}

.background-center {
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
}

.is-on-top {
    position: relative;
    z-index: 1;
}

.row-center {
    justify-content: center;
}

.flex-center-vertical {
    display: flex;
    align-items: center;
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.no-padding {
    padding: 0;
}

.has-absolute-after,
.has-absolute-before {
    position: relative;

    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }
}

.page-template-template-rules-php, .page-template-template-forms-php {
    .page-content {
        margin-left:0;
        margin-right:0;
        margin-bottom:0;
        .intro-content {
            background:transparent;
            text-align:left;
            padding:0;

            h2 {
                color:$purple;
                font-size:26px;
                margin-bottom:20px;
            }
        }
    }
}

//Where this class is called you only need to use the css border color.
.has-border-top-bottom {
    border-top: 1px solid $white;
    border-bottom: 1px solid $white;
}

.page-content {
    margin:45px 20px; 
    .intro-content {
        background:$purple;
        color:white;
        text-align: center;
        padding:20px;
        p {
            @include use-meta();
        }
    }
}