// --------------------------------------------------------------------------
// Headings
// --------------------------------------------------------------------------
h1, h2, h3, h4, h5, h6 {
  @include use-clan-bold();
  margin: 0 0 10px 0;
  span {
    @include use-clan();
    font-weight:normal;
  }
}

h1 {
    $map: (320px: 16px, 768px: 17px, 992px: 18px);
    @include poly-fluid-sizing('font-size', $map);
}

h2,
.h2 {
    $map: (320px: 17px, 768px: 25px, 992px: 27px);
    @include poly-fluid-sizing('font-size', $map);
}

h3,
.h3 {
    $map: (320px: 16px, 768px: 17px, 992px: 18px);
    @include poly-fluid-sizing('font-size', $map);
}

h4,
.h4 {
    $map: (320px: 16px, 768px: 17px, 992px: 18px);
    @include poly-fluid-sizing('font-size', $map);
}

h5
.h5 {
    $map: (320px: 16px, 768px: 17px, 992px: 18px);
    @include poly-fluid-sizing('font-size', $map);
}

h6,
.h6 {
    $map: (320px: 13px, 768px: 15px, 992px: 16px);
    @include poly-fluid-sizing('font-size', $map);
}



/*
 |----------------------------------------------------------------
 |  Paragraph
 |----------------------------------------------------------------
 */
p {
    margin: 0;
    padding-bottom: 25px;
    $map: (320px: 13px, 768px: 16px, 992px: 17px);
    @include poly-fluid-sizing('font-size', $map);

    &:last-of-type {
        padding-bottom: 0;
    }

    &:empty {
        display: none;
    }

    a {
        color: $main-text-link-colour;
    }
}

strong {
    font-weight: normal;
    @include use-clan-bold();
}

small {
    font-size:90%;
}
// --------------------------------------------------------------------------
// Links
// --------------------------------------------------------------------------
a {
    text-decoration: none;
    -webkit-text-decoration-skip: objects; // Remove gaps in links underline in iOS 8+ and Safari 8+.
    transition: all 275ms ease;

    &:hover, &:focus, &:active {
        text-decoration: none;
    }
}
