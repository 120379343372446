@include media-breakpoint-up(lg) {

	.error404 .theme-wrap .row-content-container,
	.page-template-template-login-php .theme-wrap .row-content-container {
		//height: calc(100vh - 253px);
	}


	.page-template-template-login-php{
	 	.theme-wrap {

			background-position: center bottom;
		}
	}
	header.banner {
		height:100px;

	}
	.fields-column {
	    width:48%;float:left;
	    margin-right:1%;
	}
	.form-tabs {
		ul {
			li {
				a {
					font-size:16px;
				}
			}
		}
	}
	.gfield_checkbox {
	    li {
	        margin-bottom:25px !important;
	    }

	}
	.page-template-template-rules-php, .page-template-template-forms-php {

		&.creative-engine, &.page-id-41 {
		 	> .theme-wrap {
				background-position: 643px 290px;
			}
		}

		.page-content {
		    margin: 120px 0px 0px;
		    .intro-content {
				margin-bottom:100px;
		    	h2 {
		    		font-size:48px;
		    		margin-bottom:60px;
		    	}
		    }
		}

	}

	footer.footer {
		margin-top:200px;

		.footer-content	{
			align-items: center;

			.footer-addresses {
				padding-left:38px;
				.footer-address {
					    margin-right: 25px;
	    				padding-right: 25px;
				}
			}
			.footer-text {
				margin-top:58px;
				p {
					font-size:17px;
				}
			}
		}
	}

}
