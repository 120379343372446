@media print {

	header, footer, .mobile-forms-select, .form-tabs, h1 span, h2 span, h3 span, h4 span, h5 span, h6 span, h3 span img, .gform_button {
		display: none !important;
	}

	#forms-container {
		width:100%;
		flex: 0 0 100%;
    	max-width: 100%;
	}
	.gform_wrapper ul.gform_fields li.gf_left_half, .gform_wrapper ul.gform_fields li.gf_right_half, .gf_large_field {
		margin-left:16px !important;
		margin-right:16px !important;
		padding-left:0px !important;
		padding-right:0px !important;
	}

}