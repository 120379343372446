@include media-breakpoint-up(md) {

	.error404 .theme-wrap .row-content-container,
	.page-template-template-login-php .theme-wrap .row-content-container {
		//height: calc(100vh - 214px);
	}


	header.banner {
		height:60px;
	}

	header, footer {
		.container-fluid {
		    padding-right: 30px;
		    padding-left: 30px;

		}
	}

	.gfield_checkbox, .gfield_radio {
	    li {
	        margin-bottom:20px !important;
	    }

	}
	.page-content {
	    margin: 45px 0px;
	}

	.login-register-lost-password {
	    bottom: 95px; // .wppb-fields-container PLUS .login-submit height
	}

	.wppb-fields-container {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding-bottom:20px;
		h6 {
			flex: 0 0 140%;
    		max-width: calc(100% + 40px);
		}
		p {
			flex-basis: 48%;
			padding-bottom:0;

		}
		ul {
			width:100%;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			li {
				flex-basis: 48%;
				padding-bottom:0;
			}
		}
	}
	.page-template-template-login {
		.content {

			main {
				height:100%;
				.container {
					display: flex;
					height:100%;
					.row {
						flex:1;
						height:100%;
						align-items: center;
					}
				}
			}
		}
	}
	.theme-wrap {
		> .container {
			padding-bottom:0;
			display: flex;
			> {
				.content {
					flex:1;

				}
			}
		}
	}

	#loginform {
		display: flex;
		flex-wrap: wrap;

		.form-header {
			flex-basis: 120%;
			max-width: 120%;
		}

		.login-username,
		.login-password {
			flex-basis: 48%;
			max-width: 48%;
		}

		.login-username {
			margin-right: 4%;
		}

		.login-submit {
			flex-basis: 100%;
			max-width: 100%;
		}

	}


	// gravity forms
	body {
	    .gform_wrapper {



	    	#input_6_14, #input_4_23 {
			    li {
			        width:49%;
			        float:left;
			    }
			}

	        ul {

	            li{
	            	&.gfield {
		                margin-top:0;
		                margin-right:0px;
		                margin-left:0px !important;
	            	}
	            	.ginput_container {
	                    margin-top:0 !important;
	                }
	                &.gf_left_half {
	                	padding-left:12px;
	                }
	                &.gf_right_half {
	                	padding-right:12px;
	                }
	                .ginput_container {
	                    margin-top:0;
	                }
	                .ginput_container_checkbox {
	                	padding-left:12px;
	                }
	                .gfield_html_formatted {
	                	padding-left:12px;
	                	margin-bottom:20px;
	                }
	            }


	            li.gsection {
	                background:$gold;
	                text-align: center;
	                padding:7px ;
	                padding-top:7px !important;
	                margin:0 !important;
	                margin-bottom:20px !important;
	                h2 {
	                    @include use-clan();
	                    font-weight:bold;
	                    text-transform: uppercase;
	                    font-size:15px;
	                    color:white;
	                }
	                .gsection_description {
	                    font-size:12px;
	                    margin-bottom:0;
	                    color:white;
	                }
	            }

	        }
	        .gform_footer {
			    text-align: right;
			}
	    }
	}

	.form-tabs {
		ul {
			li {
				a {
					font-size:15px;
				}
			}
		}
	}

	.page-template-template-rules-php, .page-template-template-forms-php {

		.page-content {
		    margin: 80px 0px 0px;
		    .intro-content {

		    	h2 {
		    		font-size:38px;
		    		margin-bottom:30px;
		    	}
		    }
		}

		&.creative-engine, &.page-id-41 {
		 	> .theme-wrap {
				background-position: 314px 76px;
			}
		}

	}


	footer.footer {
		margin-top:160px;
		.footer-content {

			.footer-addresses {

				margin-top:0;
				padding-left:30px;
				flex:auto;
				flex-shrink: 1;
				display: flex;
				.footer-address {
					padding-bottom:0;
					margin-bottom:0;
					border-bottom:0;
					margin-right:15px;
					padding-right:15px;
					border-right:1px solid $gold;
					&:last-child {
						border-right:0;
						margin-right:0;
						padding-right:0;
					}
				}

			}
			.footer-text {
				order:1;
				align-items:flex-end;
			}
		}
	}
}
