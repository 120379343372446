::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #9B9B9B;
}
::-moz-placeholder { /* Firefox 19+ */
  color: #9B9B9B;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #9B9B9B;;
}
:-moz-placeholder { /* Firefox 18- */
  color: #9B9B9B;
}

.wppb_holder, .redirect_message {
    padding: 10px;
    background: white;
    text-align: center;
}
.wppb-user-forms {
    a {
        color:$purple;

    }
}
.wppb-checkbox-terms-and-conditions {
    a {
        text-decoration: underline;
    }
}
#input_6_27, #input_18_27 {
    li {
        width:49%;
        float:left;
    }
}

#field_1_17, #field_1_19 {
    width:24% !important;
    .datepicker {
       width: 96.2% !important;
    }
}
#field_1_19 {
    .datepicker {
        float:right;
    }
}
// GRAVITY FORMS OVERRIDES


.gform_ajax_spinner {
    float:left;
}



.gform_wrapper.gf_browser_chrome .gfield_error input[type=email], .gform_wrapper .gfield_radio, .gform_wrapper.gf_browser_chrome .gfield_error input[type=number], .gform_wrapper.gf_browser_chrome .gfield_error input[type=password], .gform_wrapper.gf_browser_chrome .gfield_error input[type=tel], .gform_wrapper.gf_browser_chrome .gfield_error input[type=text], .gform_wrapper.gf_browser_chrome .gfield_error input[type=url], .gform_wrapper.gf_browser_chrome .gfield_error select, .gform_wrapper.gf_browser_chrome .gfield_error textarea, .gform_wrapper.gf_browser_chrome li.gfield_error.field_sublabel_above .ginput_complex input[type=text] {
    margin-bottom:20px;
}
.gform_validation_container, .gform_wrapper .gform_validation_container, body .gform_wrapper .gform_body ul.gform_fields li.gfield.gform_validation_container, body .gform_wrapper li.gform_validation_container, body .gform_wrapper ul.gform_fields li.gfield.gform_validation_container {
    display: none!important;
    position: absolute!important;
    left: -9000px;
}

.gform_wrapper li.gfield.field_description_below+li.gsection {
    margin-top: 0px!important;
}
.gform_wrapper .field_description_below .gfield_description {
    padding-top: 0;
    margin-top: -16px;
    margin-bottom: 14px;
}

.gform_wrapper .top_label li.gfield.gf_inline {

    padding-right: 3px;

}
.gform_wrapper li.gfield.gfield_error, .gform_wrapper li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning {
    background-color: transparent;
    margin-bottom: 0px!important;
    border-top: 0;
    border-bottom: 0;
    padding-bottom: 0px !important;
    padding-top: 0px !important;
    box-sizing: border-box;
}

.gform_wrapper .gfield_checkbox li input, .gform_wrapper .gfield_checkbox li input[type=checkbox], .gform_wrapper .gfield_radio li input[type=radio] {
    vertical-align: top;
    margin-top: 2px !important;
}

.gform_wrapper .top_label input.large.datepicker, .gform_wrapper .top_label input.medium.datepicker, .gform_wrapper .top_label input.small.datepicker {
    width: 100%;
}


.gform_wrapper.gform_validation_error .gform_body ul li.gfield.gfield_error:not(.gf_left_half):not(.gf_right_half) {
    max-width: 100% !important;
    width: 100% !important;
}

.gform_wrapper li.gfield_error input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]), .gform_wrapper li.gfield_error textarea {
    border: 1px solid rgba(59, 62, 77, 0.5);
}

.gform_wrapper .validation_message {
    color: #D0021B;
}
.custom_checkbox_class {
    .validation_message {
        display: none;
    }
}
.gform_wrapper li.gfield_error div.ginput_complex.ginput_container label, .gform_wrapper li.gfield_error ul.gfield_checkbox, .gform_wrapper li.gfield_error ul.gfield_radio {
    color: #D0021B;
}
.gform_wrapper .gfield_checkbox li label, .gform_wrapper .gfield_radio li label {

    font-size: 1em;

}


.gfield_checkbox {
    li {
        margin-bottom:15px !important;
    }

}
.gfield_html {
    @include use-meta();
}
.gf_separator_field {
    margin-top:0px !important;
    margin-bottom:0px !important;
}
.gf_below_inline {
    margin-bottom:0px !important;
    margin-top:0px !important;
}
.gf_html_between {
        padding-left: 8px !important;
        padding-right: 8px !important;
        font-size:0.9em;
}

.gform_wrapper li.gf_inline {
    &.gfield_html {
        padding-right:0px !important;
        padding-left:0px !important;
    }
    .gfield_checkbox {
        margin:0;
        li {
            margin-bottom:0 !important;
            label {
                min-width:85px;
                font-size:1em;
            }
        }
    }
}
.gform_wrapper .top_label li.gfield.gf_inline.gf_inline_input {

    padding-right: 8px !important;
    padding-left: 8px !important;
    label {
        font-size:0.9em;
    }
    input {

        width:50px !important;
        margin-bottom:10px !important;
        padding:0 !important;
    }
    select {
        padding: 4px !important;
        padding-bottom: 0 !important;
    }
}



.gform_wrapper div.validation_error, #wppb_general_top_error_message {
    color: white;
    font-size: 1em;
    font-weight: 700;
    margin-bottom: 25px;
    border-top: 0;
    border-bottom: 0;
    padding: 16px 0;
    clear: both;
    width: 100%;
    background:#D0021B;
    text-align: center;
}
.gform_wrapper .top_label li.gfield.gf_inline.gf_padding_left {
    padding-left:12px !important;
}

.gform_button {
    margin-top:15px;
    margin-right:0;
}
.fields-column {
    margin-bottom: 10px;
}
#gform_wrapper_14 h6 {
    margin-top:-20px;
    width:100%;
    clear:both;
    padding:5px 12px!important;
}

#wppb-login-wrap {
    position: relative;
}

.login-register-lost-password {
    @include use-meta();
    text-align: center;
    position: absolute;
    bottom: 120px; // .wppb-fields-container PLUS .login-submit height
    font-size: 16px;
    right: 20px;
}
#wppb_general_top_error_message {
    margin-bottom:0;
}
.wppb_form_success_message, #wppb_form_success_message{
    background:white;
    padding-bottom:12px;
    font-size:14px;
}

.wppb-error, .wppb-warning {
    color: white;
    font-size: 1em;
    font-weight: 700;
    margin-bottom: 0px;
    border-top: 0;
    border-bottom: 0;
    padding: 16px !important;;
    clear: both;
    width: 100%;
    background:#D0021B;
    text-align: center;
    a {
        color:white;
    }
}


.wppb-form-error {
    margin-top:-10px;
    margin-bottom:10px;
    color:#D0021B;
    display: inline-block;
}


.wppb-fields-container, #wppb-recover-password {
    background:white;
    padding:20px;
    padding-top:0;
    ul {
        margin:0;
        padding:0;
        li {
            list-style:none;
            &:last-child {
                [type="text"],
                [type="url"],
                [type="email"],
                [type="tel"],
                [type="number"],
                [type="password"],
                [type="search"],
                select,
                textarea {
                    margin-bottom:0;
                }
            }
        }
    }
}


.wppb-fields-container {
    padding-bottom:45px;
}
label {
    font-size:14px;
}

.gform_wrapper ul.gfield_checkbox li input[type=checkbox], .gform_wrapper ul.gfield_radio li input[type=radio] {
    width: 19px !important
}
.wppb-checkbox-terms-and-conditions {
    margin-left:4px;

    input[type=checkbox] {
      opacity: 1;
    }
}
input[type=radio],
input[type=checkbox] {
  opacity: 0;
  float:left;
  width: 20px !important
}

input#rememberme {
    width: 1px!important;
}
input[type=radio] + label,
input[type=checkbox] + label {
  margin: 0 0 0 20px;
  position: relative;
  cursor: pointer;
  font-size: 16px;
}

input[type=radio] + label ~ label,
input[type=checkbox] + label ~ label {
  margin: 0 0 0 40px;
}

input[type=checkbox] + label::before, input[type=radio] + label::before {
  content: " ";
    position: absolute;
    left: -25px;
    top: 2px;
    width: 16px;
    height: 16px;
    display: block;
    background: #fff;
    border:1px solid rgba(59, 62, 77, 0.5);
}
input[type=radio] + label::before {
    left:-24px;
}

.login-remember {

    label {
        position: relative;
        padding-left: 20px;

        &:after {
                content: " ";
              position: absolute;
              left: 0;
              top: 1px;
              width: 16px;
              height: 16px;
              display: block;
            z-index: 1;
            border: 1px solid black;
            transition: all .3s ease;
        }
    }

    &.is-selected {

        label{

            &:after {
            background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjE4MS4yIDI3MyAxNyAxNiIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAxODEuMiAyNzMgMTcgMTYiPjxwYXRoIGQ9Ik0tMzA2LjMgNTEuMmwtMTEzLTExM2MtOC42LTguNi0yNC04LjYtMzQuMyAwbC01MDYuOSA1MDYuOS0yMTIuNC0yMTIuNGMtOC42LTguNi0yNC04LjYtMzQuMyAwbC0xMTMgMTEzYy04LjYgOC42LTguNiAyNCAwIDM0LjNsMjMxLjIgMjMxLjIgMTEzIDExM2M4LjYgOC42IDI0IDguNiAzNC4zIDBsMTEzLTExMyA1MjQtNTI0YzctMTAuMyA3LTI1LjctMS42LTM2eiIvPjxwYXRoIGZpbGw9IiMzNzM3MzciIGQ9Ik0xOTcuNiAyNzcuMmwtMS42LTEuNmMtLjEtLjEtLjMtLjEtLjUgMGwtNy40IDcuNC0zLjEtMy4xYy0uMS0uMS0uMy0uMS0uNSAwbC0xLjYgMS42Yy0uMS4xLS4xLjMgMCAuNWwzLjMgMy4zIDEuNiAxLjZjLjEuMS4zLjEuNSAwbDEuNi0xLjYgNy42LTcuNmMuMy0uMS4zLS4zLjEtLjV6Ii8+PHBhdGggZD0iTTExODcuMSAxNDMuN2wtNTYuNS01Ni41Yy01LjEtNS4xLTEyLTUuMS0xNy4xIDBsLTI1My41IDI1My41LTEwNi4yLTEwNi4yYy01LjEtNS4xLTEyLTUuMS0xNy4xIDBsLTU2LjUgNTYuNWMtNS4xIDUuMS01LjEgMTIgMCAxNy4xbDExNC43IDExNC43IDU2LjUgNTYuNWM1LjEgNS4xIDEyIDUuMSAxNy4xIDBsNTYuNS01Ni41IDI2Mi0yNjJjNS4yLTMuNCA1LjItMTIgLjEtMTcuMXpNMTYzNC4xIDE2OS40bC0zNy43LTM3LjdjLTMuNC0zLjQtOC42LTMuNC0xMiAwbC0xNjkuNSAxNjkuNS03MC4yLTcxLjljLTMuNC0zLjQtOC42LTMuNC0xMiAwbC0zNy43IDM3LjdjLTMuNCAzLjQtMy40IDguNiAwIDEybDc3LjEgNzcuMSAzNy43IDM3LjdjMy40IDMuNCA4LjYgMy40IDEyIDBsMzcuNy0zNy43IDE3NC43LTE3Ni40YzEuNi0xLjcgMS42LTYuOS0uMS0xMC4zeiIvPjwvc3ZnPg==') no-repeat center center;
        }
    }
    }
}


input[type=checkbox] + label::after, input[type=radio] + label::after {
      content: " ";
    position: absolute;
    left: -25px;
    top: 1px;
    width: 16px;
    height: 16px;
    display: block;
  z-index: 1;
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjE4MS4yIDI3MyAxNyAxNiIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAxODEuMiAyNzMgMTcgMTYiPjxwYXRoIGQ9Ik0tMzA2LjMgNTEuMmwtMTEzLTExM2MtOC42LTguNi0yNC04LjYtMzQuMyAwbC01MDYuOSA1MDYuOS0yMTIuNC0yMTIuNGMtOC42LTguNi0yNC04LjYtMzQuMyAwbC0xMTMgMTEzYy04LjYgOC42LTguNiAyNCAwIDM0LjNsMjMxLjIgMjMxLjIgMTEzIDExM2M4LjYgOC42IDI0IDguNiAzNC4zIDBsMTEzLTExMyA1MjQtNTI0YzctMTAuMyA3LTI1LjctMS42LTM2eiIvPjxwYXRoIGZpbGw9IiMzNzM3MzciIGQ9Ik0xOTcuNiAyNzcuMmwtMS42LTEuNmMtLjEtLjEtLjMtLjEtLjUgMGwtNy40IDcuNC0zLjEtMy4xYy0uMS0uMS0uMy0uMS0uNSAwbC0xLjYgMS42Yy0uMS4xLS4xLjMgMCAuNWwzLjMgMy4zIDEuNiAxLjZjLjEuMS4zLjEuNSAwbDEuNi0xLjYgNy42LTcuNmMuMy0uMS4zLS4zLjEtLjV6Ii8+PHBhdGggZD0iTTExODcuMSAxNDMuN2wtNTYuNS01Ni41Yy01LjEtNS4xLTEyLTUuMS0xNy4xIDBsLTI1My41IDI1My41LTEwNi4yLTEwNi4yYy01LjEtNS4xLTEyLTUuMS0xNy4xIDBsLTU2LjUgNTYuNWMtNS4xIDUuMS01LjEgMTIgMCAxNy4xbDExNC43IDExNC43IDU2LjUgNTYuNWM1LjEgNS4xIDEyIDUuMSAxNy4xIDBsNTYuNS01Ni41IDI2Mi0yNjJjNS4yLTMuNCA1LjItMTIgLjEtMTcuMXpNMTYzNC4xIDE2OS40bC0zNy43LTM3LjdjLTMuNC0zLjQtOC42LTMuNC0xMiAwbC0xNjkuNSAxNjkuNS03MC4yLTcxLjljLTMuNC0zLjQtOC42LTMuNC0xMiAwbC0zNy43IDM3LjdjLTMuNCAzLjQtMy40IDguNiAwIDEybDc3LjEgNzcuMSAzNy43IDM3LjdjMy40IDMuNCA4LjYgMy40IDEyIDBsMzcuNy0zNy43IDE3NC43LTE3Ni40YzEuNi0xLjcgMS42LTYuOS0uMS0xMC4zeiIvPjwvc3ZnPg==') no-repeat center center;
  transition: all .3s ease;
  transform: scale(0);
  opacity: 0;
}
input[type=radio] + label::after {
    left:-24px;
}
input[type=checkbox]:checked + label::after , input[type=radio]:checked + label::after {
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

.gchoice_select_all, .gform_wrapper ul.gfield_checkbox li input[type=checkbox]:checked+label, .gform_wrapper ul.gfield_radio li input[type=radio]:checked+label {
font-weight: 300;
}

.wppb-description-delimiter, .wppb-send-credentials-checkbox {
    display:none;
}


.form-header, body.recover-password .intro-content h2 {
    background:$gold;
    color:white;
    text-align: center;
    padding-top:10px;
    padding-bottom:7px;
    margin-right:-20px;
    margin-left:-20px;
    margin-bottom:20px;
    text-transform: uppercase;
}
body.recover-password {
    main {
        > .container {
            height:100%;
            .justify-content-md-center {
                height:100%;
            }
        }
    }
    .row-content-container {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
    }
    .intro-content {
            background: #3b3e4d;
            padding:0;
            h2 {
                margin-bottom:0;
                margin-right:0px;
                margin-left:0px;
                font-size:16px;
                font-weight: bold;
            }
    }
}
#wppb-recover-password {
    padding-top:20px;
    text-align: center;
}
.login-submit {
    text-align: center;
}
#wppb-submit {
    display: inline-block; // foce the button to centre on IE
}
.form-submit {
    text-align: center;
}

.select2 {
    width:100% !important;
    .select2-selection {
        @include use-clan-bold();
        background:$gold;
        border:0;
        border-radius:0;
        height:65px;

        font-size:16px;
        .select2-selection__rendered {
            line-height:65px;
            color:white;
        }
    }

}
.select2-search--dropdown .select2-search__field {
    margin-bottom:0;
    padding: 9px 11px 7px;
}
.select2-dropdown {
    border: 1px solid $gold;
    border-radius: 0px;

}
.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 55px;
    position: absolute;
    top: 1px;
    right: 10px;
    width: 20px;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: $gold;
    color: white;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
        border-color: white transparent transparent transparent;
        border-style: solid;
        border-width: 10px 6px 0 6px;
        height: 0;
        left: 50%;
        margin-left: -4px;
        margin-top: -2px;
        position: absolute;
        top: 50%;
        width: 0;
    }
[type="text"],
[type="url"],
[type="email"],
[type="tel"],
[type="number"],
[type="password"],
[type="search"],
select,
textarea {
    @include use-meta();
    transition-duration: .28s;
    transition-property: background-color, color, border, opacity, transform;
    box-shadow: none;
    border-radius: none;
    outline: none;
    border: 0;
    border: 1px solid rgba(59, 62, 77, 0.5);
    background: white;
    padding: 9px 11px 7px !important;
    font-size:14px;
    // height: 50px;
    border-radius:0;
    font-weight: 300;
    width: 100%;
    appearance: none;
    margin-bottom:20px;
    &:focus {
        outline: none;
        // border-color: $highlight;
    }

}
[type="checkbox"] {
    margin-right:5px;
}
label {
    @include use-meta();
}
select,
textarea {
    color: $main-text;
}

select::-ms-expand {
    display: none;
}

select{
    -webkit-appearance: none;
    appearance: none;
}

// datepicker

#ui-datepicker-div {
    .ui-datepicker-header {
        background-color:$purple;
    }
    .ui-datepicker-month, .ui-datepicker-year {
        margin-bottom:2.5%;
        width:30%;
        padding-top:0 !important;
        padding-bottom:0 !important;
        border:0;
    }
    .ui-datepicker-next, .ui-datepicker-prev {
        margin-top:7px;
    }
}

body {
    .gform_wrapper {

        margin: 0px 0;

        textarea.small {
            height: 60px;
            resize: vertical;
        }


        li.hidden_label input {
            margin-top: 0px;
        }
    }
    .gform_body {
        border: solid 1px rgba(59, 62, 77, 0.3);
        margin-top:0;
        padding-bottom:10px;
        ul {

            li.gfield {
                margin-top:0;
                margin-right:20px;
                margin-left:20px !important;
                .ginput_container {
                    margin-top:0;
                }

            }

            .gfield_html.callout-note {
                margin: 0 0 4px;
                position: relative;
                top: -16px;
            }

            .gfield_html_formatted {
                    padding-left:12px !important;
                    margin-bottom:20px;
                    a {
                        color:$purple;
                    }
             }

            .gf_large_field {
                padding-right:12px;
                padding-left:12px;
            }
            .datepicker {
                margin-bottom:0;
            }

            .ginput_container_checkbox + .validation_message {
                    margin-left:14px;
                }

            li.gsection {
                background:$gold;
                text-align: center;
                padding:7px 12px 5px !important;
                margin:0 !important;
                margin-bottom:20px !important;
                h2 {
                    @include use-clan();
                    font-weight:bold;
                    text-transform: uppercase;
                    font-size:15px;
                    color:white;
                    float:left;
                }
                .gsection_description {
                    font-size:12px;
                    margin-bottom:0;
                    color:white;
                    float:right;
                    width:auto;
                    clear:none;
                    padding-right:0;
                    display: none;

                }

                &.gfield_error {
                    background:$gold;
                    padding:7px 12px 5px !important;
                    margin-bottom:20px !important;
                    max-width:100% !important;
                }
                &:after {
                    content: "";
                    display: table;
                    clear: both;
                }
            }
        }
    }
}

.gform_wrapper .gform_footer {
    margin: 0px 0 0;

    text-align: center;
}

.gfield {

    margin-top:0;

    &:not(.show-label) {

        .gfield_label{
            display: none;
        }
    }

    .gfield_label {
        font-weight: $bold;
        letter-spacing: 0.6px;
    }

    .gfield_required {
        position: relative;
        top: 9px;
        right: -2px;
        line-height: 1;
        font-size: 22px;
    }
}

.gfield.has-icon-after {
    position: relative;

    &:after {
        width: 8px;
        height: 8px;
        border-right: 1px solid $main-text;
        border-bottom: 1px solid $main-text;
        position: absolute;
        content: '';
        right: 25px;
        top: 14px;
        transform: rotate(45deg);
    }
}

.form-end-icon {
    width: 90px;
    height: 90px;
    //background: $light-grey;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;

    &:after {
        content: "n";
        //color: $med-grey;
        font-size: 30px;
        position: relative;
        top: -17px;
    }
}

@media (max-width:641px) {
    #elevator-access-times {
        .gform_body ul li.gfield {
             margin-right: 10px;
            margin-left: 10px!important;
        }
        .cbg1, .cbg2 {
            display: inline-block;
        }
        #field_14_60 {
            display: none;
        }
        #field_14_13 {
            padding-left:0 !important;
        }
        #gform_wrapper_14 h6 {
            text-align: center;
            margin-top:-0px;
        }
        #field_14_58 {
            margin-top:0 !important;
        }
        .gf_below_inline {
            display: block !important;
        }
        .gform_wrapper .top_label li.gfield.gf_inline.gf_inline_input select {
            margin-bottom: 10px;
        }
        .gform_wrapper .top_label li.gfield.gf_inline.gf_inline_input input {
            padding-left:2px;
            padding-right:2px;

        }

        .gform_wrapper div.gform_body ul.gform_fields li.gfield.gfield_html {
            display: inline-block;
        }
        .gform_wrapper .top_label li.gfield.gf_inline.gf_padding_left {
            padding-left: 0px!important;
        }
        .gf_to_message{
            margin-bottom:10px;
            margin-top:-10px;
            vertical-align: bottom;
        }
        .gform_wrapper .top_label li.gfield.gf_inline.gf_inline_input {
            margin-right:-15px !important;
            margin-left:-15px !important;
            display: inline-block;
            vertical-align: top;
            margin-top: 9px;
        }
        .gform_wrapper ul.gfield_checkbox li label, .gform_wrapper ul.gfield_radio li label {
            width:auto;
        }
    }
}
@media (max-width:330px) {
    #elevator-access-times {
        .gform_body ul li.gfield {
             margin-right: 10px;
            margin-left: 10px!important;
        }
    }
}


