.button {
    @include use-clan-bold();
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.5px;
    text-decoration: none;
    text-shadow: none;
    outline: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 15px 30px 14px;
    background:$purple;
    color:white;
    margin:20px;
    margin-bottom:0;
    text-transform: uppercase;
    font-weight:bold;
    cursor:pointer;
    border: 1px solid transparent;
}
