//Text colours


//Site colours
$white : #fff;
$light-grey : #fff;
$grey : #fff;
$dark-grey : #3b3e4d;
$purple : #3b3e4d;
$gold : #a8996e;
$light-gold : #efebe2;
$main-heading : #fff;

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;



$main-text : $dark-grey;
$main-text-link-colour: $dark-grey;