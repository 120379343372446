.page-template-template-forms-php {
	
	.mobile-form-select {
		margin-bottom:45px;
		 p {
		 	margin-bottom:10px;
		 }
	}
	.form-title {
		font-size: 16px;
		letter-spacing: -0.1px;
		margin-bottom:20px;
		span {
			float: right;
			margin-top:-5px;
		}
	}
	.form-tabs {
		ul {
			margin:0;
			padding:0;
			list-style:none;
			li {
				margin:0;
				padding:0;
				a {
					background:$light-gold;
					color:$purple;
					display: block;
					border-bottom: solid 1px rgba(59, 62, 77, 0.3);
					padding:20px 13px 16px;
				}
				&.selected {
					a {
						@include use-clan-bold();
						background:$gold;
						color:white;
					}
				}
				&:last-child {
					a {
						border-bottom:0;
					}
				}
			}
		}
	}
	.forms-container {
		.form-div {
			display:none;
			&.selected {
				display: block;
			}
		}
		
	}
	
	&.creative-engine, &.page-id-41 {
		 > .theme-wrap {
		 	
		background-position: 59px 127px;
	    background-attachment: scroll;
		 }
		
	}
    
}