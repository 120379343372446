
.page-template-template-login-php, .error404{
	min-height:100vh;
	.theme-wrap {
		background-position: center;

		>.container {
			flex: none;
			display: block;

			>.content {
				flex: none;

				main {
					height: auto;

					.container {
						flex: none;
						display: block;

						.row {
							flex: none;
							height: auto;
						}
					}
				}
			}
		}

		.row-content-container {
			align-items: center;
			min-height: calc(100vh - 388px);
			display: flex;
		}
	}
	#loginform {
		overflow: hidden;
		background: #fff;
		padding: 0 20px 20px 20px;

		p {
			padding-bottom:0;
		}
	}
	.page-content {


	}
	.intro-content  {
		p {
			padding-bottom:10px;
			&:last-of-type {
			    padding-bottom: 0;
			}
		}
	}
	.footer {
		margin-top:0;
	}
}
body.log-in {
	.intro-content {
		h2 {
			br {
				display: none;
			}
		}
	}
}

body.register {
	#wppb_form_success_message {
		display:none;
	}
}

