@media (min-width: 1200px)  {

	.wppb-user-forms {
		a{
			&:hover {
				text-decoration:underline;
			}
		}
	}
	
	.form-tabs {
		ul {
			li {
				a{
					&:hover {
						background:$gold;
						color:white;
					}
				}
			}
		}
	}
	.button:hover {
		color:white;
	}

}
