footer.footer {
	background:$purple;
	color:white;
	padding-top:30px;
	padding-bottom:30px;
	
	.footer-content	{
		display:flex;
		flex-wrap: wrap;
		
		.footer-text {
			flex-grow: 1;
    		text-align: right;
    		display: flex;
    		align-items: center;
    		justify-content: flex-end;

    		@include use-clan-bold();
    		a {
					color:white;
				}
    		p {
    			font-size:14px;
    			padding-bottom:0;
    			line-height:100%;
    		}
		}
		.footer-addresses {

			margin-top:20px;
			flex: 0 0 100%; /* fg: 0, fs: 0, fb: 100% */
			.footer-address {
				border-bottom:1px solid $gold;
				margin-bottom:10px;
				padding-bottom:10px;
				p {
					font-size:13px;
				}
				a {
					color:white;
				}
				h6 {
					margin-bottom:0;
				}
				&:last-of-type {
					border-bottom:0;
					margin-bottom:0px;
					padding-bottom:0px;
				}
			}
		}
	}
}