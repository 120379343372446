// --------------------------------------------------------------------------
// Fonts Weight
// --------------------------------------------------------------------------
$thin    : 100;
$light   : 300;
$normal  : 400;
$regular : 400;
$medium  : 500;
$semi    : 600;
$bold    : 700;
$x-bold  : 900;




// --------------------------------------------------------------------------
// Web font
// --------------------------------------------------------------------------
@font-face {font-family: 'FFClanWebProBook';src: url('../fonts/37C063_0_0.eot');src: url('../fonts/37C063_0_0.eot?#iefix') format('embedded-opentype'),url('../fonts/37C063_0_0.woff2') format('woff2'),url('../fonts/37C063_0_0.woff') format('woff'),url('../fonts/37C063_0_0.ttf') format('truetype');}
@font-face {font-family: 'FFMetaSerifWebProBook';src: url('../fonts/37C063_1_0.eot');src: url('../fonts/37C063_1_0.eot?#iefix') format('embedded-opentype'),url('../fonts/37C063_1_0.woff2') format('woff2'),url('../fonts/37C063_1_0.woff') format('woff'),url('../fonts/37C063_1_0.ttf') format('truetype');}
@font-face {font-family: 'FFClanWebProBold';src: url('../fonts/37C063_2_0.eot');src: url('../fonts/37C063_2_0.eot?#iefix') format('embedded-opentype'),url('../fonts/37C063_2_0.woff2') format('woff2'),url('../fonts/37C063_2_0.woff') format('woff'),url('../fonts/37C063_2_0.ttf') format('truetype');}
 

 // --------------------------------------------------------------------------
// Fonts Mixins
// --------------------------------------------------------------------------

@mixin use-clan() {
  font-family: 'FFClanWebProBook', Helvetica, Arial, sans-serif;
}

@mixin use-clan-bold() {
  font-family: 'FFClanWebProBold';
}

@mixin use-meta() {
	font-family: 'FFMetaSerifWebProBook' !important;
}

