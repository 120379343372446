@include media-breakpoint-up(xl) {
	
 	footer.footer {
 		
		.footer-content	{
 
			.footer-addresses {
 				.footer-address {
					    margin-right: 38px;
	    				padding-right: 38px;
				}
			}
		}
	}

	.page-template-template-rules-php, .page-template-template-forms-php {

		&.creative-engine, &.page-id-41 {
		 	> .theme-wrap {
			    background-position: center right !important;
			}
		}
	}
}
@media (min-width: 1300px)  {

	.page-template-template-login-php{
	 	.theme-wrap {

			background-position: center bottom;
		}
	}
}	

@media (min-width: 1600px)  {

	.page-template-template-login-php{
	 	.theme-wrap {

			background-position: center bottom;
		}
	}
}	


@media (min-width: 2000px)  {
.page-template-template-login-php{
	 	.theme-wrap {

			background-position: center bottom;
		}
	}
	
}